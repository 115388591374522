import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

const checkLogin = async (username, password) => {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    });

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({ Username: username, Pool: userPool });
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => resolve(true),
      onFailure: (e) => reject(e),
    });
  });
};

export default checkLogin;
