<script>
import { mapActions, mapGetters } from 'vuex';
import findUserCognitoId from '@/api/user/findUserCognitoId';
import checkLogin from '../../plugins/auth/checkLogin';

export default {
  name: 'AdminUserMasquerade',
  data: function () {
    return {
      email: '',
      loading: false,
      password: '',
      success: '',
      error: '',
      id: undefined,
    };
  },
  computed: {
    ...mapGetters('auth', ['cognitoUserId', 'displayName', 'isMasqueraded', 'username']),
  },
  methods: {
    ...mapActions('auth', ['setMasqueradedCognitoId', 'setMasqueradedDisplayName']),
    beginMasquerade: async function () {
      this.success = '';
      this.error = '';
      this.loading = true;
      try {
        await checkLogin(this.username, this.password);
        this.password = '';
        const userCognitoId = await findUserCognitoId(String(this.$i18n.t('site.subscriberId')), this.email);
        if (userCognitoId) {
          await this.setMasqueradedUser(this.email, userCognitoId);
          this.success = `You are now logged in as ${this.email}`;
          await new Promise((resolve) => setTimeout(resolve, 1000));
          location.href = '/dashboard'; // to force it to clear computed data and redirect to dashboard
        }
      } catch (e) {
        this.error = e.message;
      }
      this.loading = false;
    },
    endMasquerade: async function () {
      this.success = '';
      await this.setMasqueradedUser(null, null);
      setTimeout(() => location.reload(), 1000);
    },
    setMasqueradedUser: async function (email, id) {
      this.email = email;
      localStorage.setItem('masqueradedUser', JSON.stringify({ email, id }));
      await this.setMasqueradedCognitoId(id);
      await this.setMasqueradedDisplayName(email);
    },
  },
  async mounted() {
    this.email = this.isMasqueraded ? this.displayName : '';
    this.id = this.isMasqueraded ? this.cognitoUserId : '';
    // Added as message will gone after location.reload() in beginMasquerade method
    this.success = this.isMasqueraded ? `You are now logged in as ${this.email}` : '';
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header bg-light">
      <h6 class="card-title">User Masquerade</h6>
    </div>

    <div class="card-body row">
      <div class="col-lg-4 offset-lg-4">
        <p>This form grants the ability to masquerade as a user providing access to their account..</p>
        <div class="form-group row mt-2">
          <label class="col-form-label col-lg-4" for="emailAddress">Email address</label>
          <div class="col-lg-8">
            <input id="emailAddress" v-model="email" class="form-control" v-on:keyup.enter="startMasquerade" />
          </div>
        </div>
        <div class="form-group row mt-2">
          <label class="col-form-label col-lg-4" for="password">Your Password</label>
          <div class="col-lg-8">
            <input
              id="password"
              v-model="password"
              class="form-control"
              type="password"
              v-on:keyup.enter="startMasquerade"
            />
          </div>
        </div>
        <div v-if="success || error" class="row">
          <div class="col">
            <p v-if="success" class="bg-green p-2">{{ success }}</p>
            <p v-if="error" class="bg-warning p-2">{{ error }}</p>
          </div>
        </div>
        <div class="row text-right p-2">
          <button
            v-if="!isMasqueraded"
            :disabled="email === '' || loading"
            class="btn btn-primary col"
            @click="beginMasquerade"
          >
            Begin Masquerade
            <i v-if="loading" class="icon-spinner11 ml-2 spinner"></i>
          </button>
          <button v-if="isMasqueraded" class="btn btn-danger col" @click="endMasquerade">End Masquerade</button>
        </div>
      </div>
    </div>
  </div>
</template>
