import axios from 'axios';

export default async (subscriber, email) => {
  const result = await axios
    .get(`${process.env.VUE_APP_API_ENDPOINT}/users/${subscriber}/${encodeURIComponent(email)}/cognito-id`)
    .catch(() => ({
      data: undefined,
    }));
  return result.data;
};
